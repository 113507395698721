<template>
  <a-spin :spinning="loading">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <!-- <h2 class="mr-3">Addition</h2> -->
        <!-- <a-tag :color="courseData.statusPublication === 'published' ? 'green' : 'orange'">{{courseData.statusPublication === 'published' ? 'TERPUBLIKASI': 'DRAF'}}</a-tag> -->
      </div>
      <!-- <div>
        <a-button @click.prevent="" type="danger">
          <LeftSquareOutlined />
          Batal</a-button
        >
      </div> -->
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <!-- <a-button @click="$router.back()"><LeftOutlined />Back</a-button>
          <a-button @click="changeEdittable">
            <span v-if="!editable"> <FormOutlined class="mr-2" />Edit </span>
            <span v-else> <EyeOutlined class="mr-2" />View </span>
          </a-button> -->
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Date</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :sm="{ span: 6 }">
                <a-date-picker
                  format="DD / MM / YYYY"
                  class=" w-100"
                  v-model:value="dataPayload.date"
                ></a-date-picker>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Installer Detail</h1>
          </div>
          <a-row :gutter="[24, 12]">
            <a-col
              :span="24"
              class="d-flex justify-content-between  align-items-center"
              style="gap: 15px;"
            >
              <div class="w-100">
                <p style="text-align: left;" class="text-label">Name</p>
              </div>
              <div class="w-100">
                <p style="text-align: left;" class="text-label">Title</p>
              </div>
              <div class="w-100">
                <p style="text-align: left;" class="text-label">Company</p>
              </div>
              <div class="w-100"></div>
            </a-col>
            <a-col
              :span="24"
              class="d-flex justify-content-between  align-items-center"
              style="gap: 15px;"
              v-for="(item, index) in installerDetailData"
              :key="index"
            >
              <div class="w-100">
                <a-input v-model:value="item.name" placeholder="Masukkan Nama"></a-input>
              </div>
              <div class="w-100">
                <a-input v-model:value="item.title" placeholder="Masukkan Title"></a-input>
              </div>
              <div class="w-100">
                <a-select class="w-100" placeholder="Pilih Company" v-model:value="item.vendorId">
                  <a-select-option
                    v-for="(itemV, indexV) in dataVendors"
                    :key="indexV"
                    :value="itemV.id"
                    >{{ itemV.name }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="w-100">
                <a-button
                  @click="deleteInstallerDetail(item._id)"
                  type="danger"
                  :disabled="item.id"
                >
                  <DeleteOutlined />
                </a-button>
              </div>
            </a-col>
            <a-col :span="24">
              <a-button @click="addInstallerDetail">
                <PlusOutlined />
              </a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">
              <!-- Section 2 - {{ assets.asset.asset_category == 3 ? 'Requestor' : 'Approval' }} -->
              Approval
            </h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Nama</p>
                <a-select
                  class="w-100 "
                  placeholder="Pilih User"
                  v-model:value="selectedSelectionRequester"
                  @change="handleChangeSelectedRequester"
                >
                  <a-select-option
                    v-for="(item, index) in dataSelectionRequester"
                    :key="index"
                    :value="item.id"
                    >{{ item.username }}</a-select-option
                  >
                </a-select>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Title</p>
                <a-input
                  v-model:value="selectedDataRequester.role"
                  class=""
                  disabled
                  placeholder="Masukkan Title"
                ></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Email</p>
                <a-input
                  v-model:value="selectedDataRequester.email"
                  class=""
                  disabled
                  placeholder="Masukkan Email"
                ></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input
                  v-model:value="selectedDataRequester.phoneNumber"
                  class=" "
                  disabled
                  placeholder="Masukkan Nomor Telepon"
                ></a-input>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Aset Detail</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :span="24">
                <h2 class="text-subtitle">Aset Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset Category</p>
                  <a-select
                    placeholder="Pilih Asset Category"
                    class="w-100"
                    v-model:value="selectedCategories"
                    @change="handleChangeCategories"
                    disabled
                  >
                    <a-select-option
                      v-for="(item, index) in dataAssetCategories"
                      :key="index"
                      :value="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset Sub Category</p>
                  <a-select
                    placeholder="Pilih Asset Sub Category"
                    class="w-100"
                    v-model:value="selectedSubCategories"
                    @change="handleChangeSubCategories"
                    disabled
                  >
                    <a-select-option
                      v-for="(item, index) in dataAssetSubCategories"
                      :key="index"
                      :value="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset</p>
                  <a-input
                    placeholder="Masukkan Nama Aset"
                    v-model:value="dataPayload.name"
                    disabled
                  ></a-input>
                  <!-- <a-select
                    placeholder="Pilih Asset"
                    class="w-100"
                    v-model:value="selectedAsset"
                    @change="handelChangeAssets"
                  >
                    <a-select-option
                      v-for="(item, index) in dataAssets"
                      :key="index"
                      :value="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select> -->
                </div>
              </a-col>
              <!--  -->
              <!--  -->
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Brand</p>
                  <a-select
                    placeholder="Pilih Manufacturer"
                    class="w-100"
                    v-model:value="dataPayload.manufacturerId"
                    disabled
                  >
                    <a-select-option
                      v-for="(item, index) in dataManufatures"
                      :key="index"
                      :value="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Model</p>
                <a-input
                  placeholder="Masukkan Model"
                  v-model:value="dataPayload.modelAsset"
                  disabled
                ></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Product No</p>
                <a-input
                  placeholder="Masukkan Product No"
                  v-model:value="dataPayload.productNumber"
                  disabled
                ></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Serial No</p>
                <a-input
                  placeholder="Masukkan Serial No"
                  v-model:value="dataPayload.serialNumber"
                  disabled
                ></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Service Tag</p>
                <a-input
                  placeholder="Masukkan Service Tag"
                  v-model:value="dataPayload.serviceTag"
                  disabled
                ></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Asset Tag</p>
                <a-input
                  placeholder="Masukkan Asset Tag"
                  v-model:value="dataPayload.assetTag"
                  disabled
                ></a-input>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mt-5">
              <a-col :span="24">
                <h2 class="text-subtitle">Procurement Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Tanggal Pembelian</p>
                <!-- <a-input  placeholder="Masukkan System Name"></a-input> -->
                <a-date-picker
                  v-model:value="dataPayload.procurements.purchaseDate"
                  format="DD / MM / YYYY"
                  class=" w-100"
                  disabled
                ></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Nomor Pembelian</p>
                <a-input
                  placeholder="Masukkan Nomor Pembelian"
                  v-model:value="dataPayload.procurements.No"
                  disabled
                ></a-input>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi</p>
                <a-input
                  placeholder="Masukkan Garansi"
                  suffix="Month(s)"
                  v-model:value="dataPayload.procurements.warranty"
                  disabled
                ></a-input>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi Mulai</p>
                <!-- <a-input  placeholder="Masukkan DNS"></a-input> -->
                <a-date-picker
                  format="DD / MM / YYYY"
                  class=" w-100"
                  v-model:value="dataPayload.procurements.warrantyStart"
                  disabled
                ></a-date-picker>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi Berakhir</p>
                <!-- <a-input  placeholder="Masukkan IP Address"></a-input> -->
                <a-date-picker
                  format="DD / MM / YYYY"
                  class=" w-100"
                  v-model:value="dataPayload.procurements.warrantyEnd"
                  disabled
                ></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Vendor</p>
                <!-- <a-input  placeholder="Masukkan OS / Nomor Serial"></a-input> -->
                <a-select
                  placeholder="Pilih Vendor"
                  class="w-100"
                  v-model:value="dataPayload.procurements.vendorId"
                  @change="handleChangeProcurementVendor"
                  disabled
                >
                  <a-select-option
                    v-for="(item, index) in dataVendors"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Email</p>
                <a-input
                  disabled
                  placeholder="Masukkan Email"
                  v-model:value="dataPayload.procurements.email"
                ></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input
                  disabled
                  placeholder="Masukkan Nomor Telepon"
                  v-model:value="dataPayload.procurements.mobileNumber"
                ></a-input>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mt-5">
              <a-col :span="24">
                <h2 class="text-subtitle">Maintenance Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Tanggal Kontrak</p>
                <!-- <a-input  placeholder="Masukkan System Name"></a-input> -->
                <a-date-picker
                  format="DD / MM / YYYY"
                  class=" w-100"
                  v-model:value="dataPayload.maintenances.purchaseDate"
                  disabled
                ></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Nomor Kontrak</p>
                <a-input
                  placeholder="Masukkan Nomor Kontrak"
                  v-model:value="dataPayload.maintenances.No"
                  disabled
                ></a-input>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi</p>
                <a-input
                  placeholder="Masukkan Garansi"
                  suffix="Month(s)"
                  v-model:value="dataPayload.maintenances.warranty"
                  disabled
                ></a-input>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi Mulai</p>
                <!-- <a-input  placeholder="Masukkan DNS"></a-input> -->
                <a-date-picker
                  format="DD / MM / YYYY"
                  class=" w-100"
                  v-model:value="dataPayload.maintenances.warrantyStart"
                  disabled
                ></a-date-picker>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi Berakhir</p>
                <!-- <a-input  placeholder="Masukkan IP Address"></a-input> -->
                <a-date-picker
                  format="DD / MM / YYYY"
                  class=" w-100"
                  v-model:value="dataPayload.maintenances.warrantyEnd"
                  disabled
                ></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Vendor</p>
                <!-- <a-input  placeholder="Masukkan OS / Nomor Serial"></a-input> -->
                <a-select
                  placeholder="Pilih Vendor"
                  class="w-100"
                  v-model:value="dataPayload.maintenances.vendorId"
                  @change="handleChangeMaintenancesVendor"
                  disabled
                >
                  <a-select-option
                    v-for="(item, index) in dataVendors"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Email</p>
                <a-input
                  disabled
                  placeholder="Masukkan Email"
                  v-model:value="dataPayload.maintenances.email"
                ></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input
                  disabled
                  placeholder="Masukkan Nomor Telepon"
                  v-model:value="dataPayload.maintenances.mobileNumber"
                ></a-input>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mt-5">
              <a-col :span="24" v-if="selectedSubCategories && selectedSubCategories !== 16">
                <div>
                  <h1 class="text-title">Technical Specification</h1>
                </div>
                <div v-if="selectedSubCategories == 19">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Name</p>
                      <a-input
                        placeholder="Masukkan Software Name"
                        v-model:value="dataPayload.softwareName"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Version</p>
                      <a-input
                        placeholder="Masukkan Software Version"
                        v-model:value="dataPayload.softwareVersion"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Access Type</p>
                      <a-select
                        placeholder="Pilih Access Type"
                        class="w-100"
                        v-model:value="dataPayload.accessType"
                        disabled
                      >
                        <a-select-option value="Manual">Manual</a-select-option>
                        <a-select-option value="Electric Card">Electric Card</a-select-option>
                        <a-select-option value="Electric Finger Print"
                          >Electric Finger Print</a-select-option
                        >
                        <a-select-option value="Other">Other</a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 18">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Name</p>
                      <a-input
                        placeholder="Masukkan Software Name"
                        v-model:value="dataPayload.softwareName"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Version</p>
                      <a-input
                        placeholder="Masukkan Software Version"
                        v-model:value="dataPayload.softwareVersion"
                        disabled
                      ></a-input>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 13 || selectedSubCategories == 14">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Air Flow</p>
                      <a-select
                        placeholder="Pilih Air Flow"
                        class="w-100"
                        v-model:value="dataPayload.airFlow"
                        disabled
                      >
                        <!-- "Down Flow", "Up Flow", "Horizontal" -->
                        <a-select-option value="Down Flow">Down Flow</a-select-option>
                        <a-select-option value="Up Flow">Up Flow</a-select-option>
                        <a-select-option value="Horizontal">Horizontal</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Cooling Design</p>
                      <a-select
                        placeholder="Pilih Cooling Design"
                        class="w-100"
                        v-model:value="dataPayload.coolingDesign"
                        disabled
                      >
                        <!-- "Room Based", "Row Based", "Rack Based" -->
                        <a-select-option value="Room Based">Room Based</a-select-option>
                        <a-select-option value="Row Based">Row Based</a-select-option>
                        <a-select-option value="Rack Based">Rack Based</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Cooling Type</p>
                      <a-select
                        placeholder="Pilih Cooling Type"
                        class="w-100"
                        v-model:value="dataPayload.coolingType"
                        disabled
                      >
                        <!-- "Air Cooling", "Water Cooling" -->
                        <a-select-option value="Air Cooling">Air Cooling</a-select-option>
                        <a-select-option value="Water Cooling">Up Flow</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Cooling Capacity</p>
                      <a-input
                        placeholder="Masukkan Cooling Capacity"
                        suffix="kWh"
                        v-model:value="dataPayload.coolingCapacity"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Input Power</p>
                      <a-input
                        placeholder="Masukkan Input Power"
                        suffix="kWh"
                        v-model:value="dataPayload.inputPowerKw"
                        disabled
                      ></a-input>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 7">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Panel Type</p>
                      <a-select
                        placeholder="Pilih Panel Type"
                        class="w-100"
                        v-model:value="dataPayload.panelType"
                        disabled
                      >
                        <!-- "Main Breaker","Main Lug","Sub","Transfer Switches" -->
                        <a-select-option value="Main Breaker">Main Breaker</a-select-option>
                        <a-select-option value="Main Lug">Main Lug</a-select-option>
                        <a-select-option value="Sub">Sub</a-select-option>
                        <a-select-option value="Transfer Switches"
                          >Transfer Switches</a-select-option
                        >
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Transfer Switch</p>
                      <a-select
                        placeholder="Pilih Transfer Switch"
                        class="w-100"
                        v-model:value="dataPayload.transferSwitch"
                        disabled
                      >
                        <!-- "Manual", "Semi-Automatic", "Automatic" -->
                        <a-select-option value="Manual">Manual</a-select-option>
                        <a-select-option value="Semi-Automatic">Semi-Automatic</a-select-option>
                        <a-select-option value="Automatic">Automatic</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Phase Type</p>
                      <a-select
                        placeholder="Pilih Phase Type"
                        class="w-100"
                        v-model:value="dataPayload.phaseType"
                        disabled
                      >
                        <!-- "Air Cooling", "Water Cooling" -->
                        <a-select-option value="1 Phase">1 Phase</a-select-option>
                        <a-select-option value="2 Phase">2 Phase</a-select-option>
                        <a-select-option value="3 Phase">3 Phase</a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 20">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Sensor Type</p>
                      <a-select
                        placeholder="Pilih Sensor Type"
                        class="w-100"
                        v-model:value="dataPayload.sensorType"
                        disabled
                      >
                        <!-- ""Temperature","Humidity","Dry Contact","Smoke Detector"" -->
                        <a-select-option value="Temperature">Temperature</a-select-option>
                        <a-select-option value="Humidity">Humidity</a-select-option>
                        <a-select-option value="Dry Contact">Dry Contact</a-select-option>
                        <a-select-option value="Smoke Detector">Smoke Detector</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Name</p>
                      <a-input
                        placeholder="Masukkan Software Name"
                        v-model:value="dataPayload.softwareName"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Version</p>
                      <a-input
                        placeholder="Masukkan Software Version"
                        v-model:value="dataPayload.softwareVersion"
                        disabled
                      ></a-input>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 15">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Name</p>
                      <a-input
                        placeholder="Masukkan Software Name"
                        v-model:value="dataPayload.softwareName"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Version</p>
                      <a-input
                        placeholder="Masukkan Software Version"
                        v-model:value="dataPayload.softwareVersion"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Suppression Type</p>
                      <a-select
                        placeholder="Pilih Suppression Type"
                        class="w-100"
                        v-model:value="dataPayload.extenguisherType"
                        disabled
                      >
                        <!-- """Water", "Inert Gas", "Chemical""" -->
                        <a-select-option value="Water">Water</a-select-option>
                        <a-select-option value="Inert Gas">Inert Gas</a-select-option>
                        <a-select-option value="Chemical">Chemical</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Fill in Date</p>
                      <a-date-picker
                        class="w-100"
                        placeholder="Pilih tanggal"
                        v-model:value="dataPayload.fillDate"
                        disabled
                      ></a-date-picker>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Expired Date</p>
                      <a-date-picker
                        class="w-100"
                        placeholder="Pilih tanggal"
                        v-model:value="dataPayload.expireDate"
                        disabled
                      ></a-date-picker>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 17">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Name</p>
                      <a-input
                        placeholder="Masukkan Software Name"
                        v-model:value="dataPayload.softwareName"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Version</p>
                      <a-input
                        placeholder="Masukkan Software Version"
                        v-model:value="dataPayload.softwareVersion"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Extinguisher Type</p>
                      <a-select
                        placeholder="Pilih Extinguisher Type"
                        class="w-100"
                        v-model:value="dataPayload.extenguisherType"
                        disabled
                      >
                        <!-- """Water", "Inert Gas", "Chemical""" -->
                        <a-select-option value="Water">Water</a-select-option>
                        <a-select-option value="Inert Gas">Inert Gas</a-select-option>
                        <a-select-option value="Chemical">Chemical</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Fill in Date</p>
                      <a-date-picker
                        class="w-100"
                        placeholder="Pilih tanggal"
                        v-model:value="dataPayload.fillDate"
                        disabled
                      ></a-date-picker>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Expired Date</p>
                      <a-date-picker
                        class="w-100"
                        placeholder="Pilih tanggal"
                        v-model:value="dataPayload.expireDate"
                        disabled
                      ></a-date-picker>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 10">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">kVa</p>
                      <a-input
                        placeholder="Masukkan kVa"
                        suffix="kVa"
                        v-model:value="dataPayload.kva"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">kW</p>
                      <a-input
                        placeholder="Masukkan kW"
                        suffix="kW"
                        v-model:value="dataPayload.kw"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Machine Type</p>
                      <a-select
                        placeholder="Pilih Machine Type"
                        class="w-100"
                        v-model:value="dataPayload.machineType"
                        disabled
                      >
                        <!-- "Manual", "Semi-Automatic", "Automatic" -->
                        <a-select-option value="Diesel">Diesel</a-select-option>
                        <a-select-option value="Gasoline">Gasoline</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">System Trigger</p>
                      <a-select
                        placeholder="Pilih System Trigger"
                        class="w-100"
                        v-model:value="dataPayload.systemTrigger"
                        disabled
                      >
                        <!-- "Manual", "Semi-Automatic", "Automatic" -->
                        <a-select-option value="Manual">Manual</a-select-option>
                        <a-select-option value="Semi-Automatic">Semi-Automatic</a-select-option>
                        <a-select-option value="Automatic">Automatic</a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 8">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Model</p>
                      <a-select
                        placeholder="Pilih Model"
                        class="w-100"
                        v-model:value="dataPayload.model"
                        disabled
                      >
                        <!-- "Tiny", "Small", "Medium", "Large" -->
                        <a-select-option value="Tiny">Tiny</a-select-option>
                        <a-select-option value="Small">Small</a-select-option>
                        <a-select-option value="Medium">Medium</a-select-option>
                        <a-select-option value="Large">Large</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Form Factor</p>
                      <a-select
                        placeholder="Pilih Form Factor"
                        class="w-100"
                        v-model:value="dataPayload.formFactor"
                        disabled
                      >
                        <!-- "Tower", "Rackmount" -->
                        <a-select-option value="Tower">Tower</a-select-option>
                        <a-select-option value="Rackmount">Rackmount</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Battery</p>
                      <a-select
                        placeholder="Pilih Battery"
                        class="w-100"
                        v-model:value="dataPayload.battery"
                        disabled
                      >
                        <!-- "Included", "Separated" -->
                        <a-select-option value="Included">Included</a-select-option>
                        <a-select-option value="Separated">Separated</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Height</p>
                      <a-input
                        placeholder="Masukkan Height"
                        v-model:value="dataPayload.height"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Width</p>
                      <a-input
                        placeholder="Masukkan Width"
                        v-model:value="dataPayload.width"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Depth</p>
                      <a-input
                        placeholder="Masukkan Depth"
                        v-model:value="dataPayload.depth"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Weight</p>
                      <a-input
                        placeholder="Masukkan Weight"
                        v-model:value="dataPayload.weight"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Backup kVa</p>
                      <a-input
                        placeholder="Masukkan Backup kVa"
                        suffix="kVa"
                        v-model:value="dataPayload.backupKva"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Backup kW</p>
                      <a-input
                        placeholder="Masukkan Backup kW"
                        suffix="kW"
                        v-model:value="dataPayload.backupKw"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Backup Duration</p>
                      <a-input
                        placeholder="Masukkan Backup Duration"
                        v-model:value="dataPayload.backupDuration"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Input kVa</p>
                      <a-input
                        placeholder="Masukkan Input kVa"
                        suffix="kVa"
                        v-model:value="dataPayload.inputKva"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Input kW</p>
                      <a-input
                        placeholder="Masukkan Input kW"
                        suffix="kW"
                        v-model:value="dataPayload.inputKw"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Output kVa</p>
                      <a-input
                        placeholder="Masukkan Output kVa"
                        suffix="kVa"
                        v-model:value="dataPayload.outputKva"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Output kW</p>
                      <a-input
                        placeholder="Masukkan Output kW"
                        suffix="kW"
                        v-model:value="dataPayload.outputKw"
                        disabled
                      ></a-input>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 11">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Battery Type</p>
                      <a-select
                        placeholder="Pilih Battery Type"
                        class="w-100"
                        v-model:value="dataPayload.batteryType"
                        disabled
                      >
                        <!-- "Manual", "Semi-Automatic", "Automatic" -->
                        <a-select-option value="VRLA">VRLA</a-select-option>
                        <a-select-option value="Lithium-Ion">Lithium-Ion</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Voltage</p>
                      <a-input
                        placeholder="Masukkan Voltage"
                        v-model:value="dataPayload.voltage"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Cells</p>
                      <a-input
                        placeholder="Masukkan Cells"
                        v-model:value="dataPayload.cells"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Controller Status</p>
                      <a-select
                        placeholder="Pilih Controller Status"
                        class="w-100"
                        v-model:value="dataPayload.controllerStatus"
                        disabled
                      >
                        <!-- "Load Shed", "Bost", "Float", "Fault" -->
                        <a-select-option value="Load Shed">Load Shed</a-select-option>
                        <a-select-option value="Bost">Bost</a-select-option>
                        <a-select-option value="Float">Float</a-select-option>
                        <a-select-option value="Fault">Fault</a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 22">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 9 }">
                      <p class="text-label">Wifi Name</p>
                      <a-input
                        placeholder="Masukkan Wifi Name"
                        v-model:value="dataPayload.wifiName"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 5 }">
                      <p class="text-label">Available Port</p>
                      <a-input
                        placeholder="Masukkan Available Port"
                        v-model:value="dataPayload.availablePort"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 5 }">
                      <p class="text-label">Working Port</p>
                      <a-input
                        placeholder="Masukkan Working Port"
                        v-model:value="dataPayload.workingPort"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 5 }">
                      <p class="text-label">Dead Port</p>
                      <a-input
                        placeholder="Pilih Dead Port"
                        class="w-100"
                        v-model:value="dataPayload.deadPort"
                        disabled
                      >
                      </a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">IP Address</p>
                      <a-input
                        placeholder="Pilih IP Address"
                        class="w-100"
                        v-model:value="dataPayload.ipAdress"
                        disabled
                      >
                      </a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Subnet Mask</p>
                      <a-input
                        placeholder="Pilih Subnet Mask"
                        class="w-100"
                        v-model:value="dataPayload.subnetMask"
                        disabled
                      >
                      </a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Gateway</p>
                      <a-input
                        placeholder="Pilih Gateway"
                        class="w-100"
                        v-model:value="dataPayload.gateway"
                        disabled
                      >
                      </a-input>
                    </a-col>
                  </a-row>
                </div>
                <div
                  v-if="
                    selectedSubCategories == 23 ||
                      selectedSubCategories == 24 ||
                      selectedSubCategories == 26
                  "
                >
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Total Port</p>
                      <a-input
                        placeholder="Masukkan Total Port"
                        v-model:value="dataPayload.totalPort"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Available Port</p>
                      <a-input
                        placeholder="Masukkan Available Port"
                        v-model:value="dataPayload.availablePort"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Working Port</p>
                      <a-input
                        placeholder="Masukkan Working Port"
                        v-model:value="dataPayload.workingPort"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                      <p class="text-label">Dead Port</p>
                      <a-input
                        placeholder="Masukkan Dead Port"
                        class="w-100"
                        v-model:value="dataPayload.deadPort"
                        disabled
                      >
                      </a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">IP Address</p>
                      <a-input
                        placeholder="Masukkan IP Address"
                        class="w-100"
                        v-model:value="dataPayload.ipAdress"
                        disabled
                      >
                      </a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Subnet Mask</p>
                      <a-input
                        placeholder="Masukkan Subnet Mask"
                        class="w-100"
                        v-model:value="dataPayload.subnetMask"
                        disabled
                      >
                      </a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Gateway</p>
                      <a-input
                        placeholder="Masukkan Gateway"
                        class="w-100"
                        v-model:value="dataPayload.gateway"
                        disabled
                      >
                      </a-input>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 25">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">System Name</p>
                      <a-input
                        placeholder="Masukkan System Name"
                        v-model:value="dataPayload.systemName"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Host Name</p>
                      <a-input
                        placeholder="Masukkan Host Name"
                        v-model:value="dataPayload.hostName"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Domain Role</p>
                      <a-input
                        placeholder="Masukkan Domain Role"
                        v-model:value="dataPayload.domainRole"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">DNS</p>
                      <a-input
                        placeholder="Masukkan DNS"
                        class="w-100"
                        v-model:value="dataPayload.dns"
                        disabled
                      >
                      </a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">IP Address</p>
                      <a-input
                        placeholder="Masukkan IP Address"
                        class="w-100"
                        v-model:value="dataPayload.ipAdress"
                        disabled
                      >
                      </a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">OS</p>
                      <a-select placeholder="Pilih OS" v-model:value="dataPayload.os" disabled>
                        <a-select-option value="MICROSOFT-11">MICROSOFT-11</a-select-option>
                      </a-select>
                      <!-- <a-input
                        placeholder="Masukkan OS"
                        class="w-100"
                        v-model:value="dataPayload.os"
                      >
                      </a-input> -->
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 27">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">UTP Type</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih UTP Type"
                        v-model:value="dataPayload.utpType"
                        disabled
                      >
                        <a-select-option
                          v-for="(item, index) in utpType"
                          :key="index"
                          :value="item"
                          >{{ item }}</a-select-option
                        >
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Node</p>
                      <a-input
                        placeholder="Masukkan Node"
                        v-model:value="dataPayload.node"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Connection</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih Connection"
                        v-model:value="dataPayload.connectionType"
                        disabled
                      >
                        <a-select-option
                          v-for="(item, index) in connectionType"
                          :key="index"
                          :value="item"
                          >{{ item }}</a-select-option
                        >
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Patch</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih Patch"
                        v-model:value="dataPayload.patch"
                        disabled
                      >
                        <!-- <a-select-option v-for="(item, index) in utpType" :key="index" :value="item">{{item}}</a-select-option> -->
                        <a-select-option value="1.0">1.0</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Cable Management</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih Cable Management"
                        v-model:value="dataPayload.cableManagement"
                        disabled
                      >
                        <a-select-option value="yes">
                          Yes
                        </a-select-option>
                        <a-select-option value="no">
                          No
                        </a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 28">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Rack Unit</p>
                      <a-input
                        placeholder="Masukkan Rack Unit"
                        v-model:value="dataPayload.rackUnit"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Cross Connection</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih Cross Connection"
                        v-model:value="dataPayload.crossConnection"
                        disabled
                      >
                        <a-select-option value="yes">
                          Yes
                        </a-select-option>
                        <a-select-option value="no">
                          No
                        </a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 29">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Rack Unit</p>
                      <a-input
                        placeholder="Masukkan Rack Unit"
                        v-model:value="dataPayload.rackUnit"
                        disabled
                      ></a-input>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 30">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">FO Type</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih UTP Type"
                        v-model:value="dataPayload.foType"
                        disabled
                      >
                        <a-select-option
                          v-for="(item, index) in foType"
                          :key="index"
                          :value="item"
                          >{{ item }}</a-select-option
                        >
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Node</p>
                      <a-input
                        placeholder="Masukkan Node"
                        v-model:value="dataPayload.node"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Core</p>
                      <a-input
                        placeholder="Masukkan Core"
                        v-model:value="dataPayload.core"
                        disabled
                      ></a-input>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Connection</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih Connection"
                        v-model:value="dataPayload.connectionType"
                        disabled
                      >
                        <a-select-option
                          v-for="(item, index) in foConnectionType"
                          :key="index"
                          :value="item"
                          >{{ item }}</a-select-option
                        >
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Patch</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih Patch"
                        v-model:value="dataPayload.patch"
                        disabled
                      >
                        <!-- <a-select-option v-for="(item, index) in utpType" :key="index" :value="item">{{item}}</a-select-option> -->
                        <a-select-option value="1.0">1.0</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Cable Management</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih Cable Management"
                        v-model:value="dataPayload.cableManagement"
                        disabled
                      >
                        <a-select-option value="yes">
                          Yes
                        </a-select-option>
                        <a-select-option value="no">
                          No
                        </a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </div>
                <div v-if="selectedSubCategories == 31">
                  <a-row :gutter="[24, 12]">
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Software Type</p>
                      <a-select
                        class="w-100"
                        placeholder="Pilih UTP Type"
                        v-model:value="dataPayload.softwareType"
                        disabled
                      >
                        <a-select-option
                          v-for="(item, index) in dataSoftwareType"
                          :key="index"
                          :value="item"
                          >{{ item }}</a-select-option
                        >
                      </a-select>
                    </a-col>
                    <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                      <p class="text-label">Vesion</p>
                      <a-input
                        placeholder="Masukkan Version"
                        v-model:value="dataPayload.softwareVersion"
                        disabled
                      ></a-input>
                    </a-col>
                  </a-row>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Addition Remarks</h1>
          </div>
          <a-row :gutter="[24, 12]">
            <a-col :xs="{ span: 24 }" :sm="{ span: 7 }" :span="7">
              <p class="text-label">Testing Reason</p>
              <!-- <a-input placeholder="Masukkan Type of Failure"></a-input> -->
              <a-select
                placeholder="Pilih Testing Reason"
                v-model:value="dataPayload.testingReason"
                class="w-100"
              >
                <!-- <a-select-option :key="1000" :value="null">Pilih</a-select-option> -->
                <a-select-option
                  v-for="(item, index) in dataTestingReason"
                  :key="index"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col :xs="{ span: 24 }" :sm="{ span: 7 }" :span="7">
              <p class="text-label">Status of Product</p>
              <!-- <a-input placeholder="Masukkan Type of Solving"></a-input> -->
              <a-select
                placeholder="Pilih Status of Product"
                v-model:value="dataPayload.productStatus"
                class="w-100"
              >
                <!-- <a-select-option :key="1000" :value="null">Pilih</a-select-option> -->
                <a-select-option
                  v-for="(item, index) in dataStatusofProduct"
                  :key="index"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </a-col>
            <a-col :xs="{ span: 24 }" :sm="{ span: 7 }" :span="7">
              <p class="text-label">Other Product Status</p>
              <a-input
                placeholder="Masukkan Other Product Status"
                v-model:value="dataPayload.otherProductStatus"
              ></a-input>
            </a-col>
            <a-col :xs="{ span: 24 }" :sm="{ span: 14 }" :span="14">
              <div
                v-for="(item, index) in dataAdditionRemark"
                :key="index"
                class="d-flex justify-content-between align-item-center w-75 my-2"
              >
                <div class="d-flex align-items-center" style="gap:10px">
                  <p class="m-0">{{ index + 1 }}.</p>
                  <p class="m-0">{{ item.questionDesc }}</p>
                </div>

                <a-radio-group
                  class="d-flex align-items-center"
                  style="gap:10px"
                  v-if="dataAnswersReq.length"
                  v-model:value="dataPayload.answers[index].answer"
                  @change="e => handleChangeAnswerRemark(e, item, index)"
                >
                  <a-radio value="yes">Yes</a-radio>
                  <a-radio value="no">No</a-radio>
                </a-radio-group>
                <a-radio-group
                  class="d-flex align-items-center"
                  style="gap:10px"
                  v-else
                  @change="e => handleChangeAnswerRemark(e, item, index)"
                >
                  <a-radio value="yes">Yes</a-radio>
                  <a-radio value="no">No</a-radio>
                </a-radio-group>
              </div>
              <!-- <p class="text-label">Explain</p>
              <a-textarea placeholder="Masukkan Explain"></a-textarea> -->
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="$router.back()" type="primary">Cancel</a-button>
              <a-button @click="handleSubmit">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { onMounted, ref, createVNode, computed, onBeforeMount } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftSquareOutlined,
  FormOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification } from 'ant-design-vue'
import { getOne, remove, getAllPendings, update } from '@/services/axios/brivice-backend'
import apiClient from '@/services/axios'
import assetsFile from '@/data/modc_dump.json'
import moment from 'moment'
import { uniqueId } from 'lodash'
import { getAllUser } from '../../services/axios/modc/user'
import { getAssetCategory, getAssets, getCategories } from '../../services/axios/modc/assets'
import { getManufactures } from '../../services/axios/modc/manufacture'
import {
  getChangeRequestById,
  getListAdditionQuestion,
  editOrCreateAddition,
  editOrCreateDismantle,
} from '../../services/axios/modc/helpdesk'
import { getVendors } from '../../services/axios/modc/vendor'

const columns = [
  {
    title: 'Nama Perangkat',
    dataIndex: 'device',
    key: 'device',
    slots: { customRender: 'device' },
    width: '15%',
  },
  {
    title: 'Nama Brand',
    dataIndex: 'brand',
    key: 'brand',
    slots: { customRender: 'brand' },
  },
  {
    title: 'Nomor Produk',
    dataIndex: 'productNo',
    key: 'productNo',
    slots: { customRender: 'productNo' },
  },
  {
    title: 'Nomor Serial',
    dataIndex: 'serialNo',
    key: 'serialNo',
    slots: { customRender: 'serialNo' },
  },
]

export default {
  components: { DeleteOutlined, PlusOutlined },
  setup() {
    let store = useStore()
    let route = useRoute()
    let router = useRouter()
    const assets = ref()
    const manufactures = ref()
    const catFacility = ref('Access Point')
    const facility = ref('Access-002')
    const selectedTestingReason = ref()
    const selectedStatusofProduct = ref()
    const selectedReparation = ref()
    const dataTestingReason = [
      'Deletion',
      'Exchange',
      'Replacement',
      // 'New Addition',
      // 'Exchange',
      // 'Replacement',
      // 'Part Failure',
      // 'Broken Part',
      // 'Other',
    ]
    const dataAdditionRemark = ref([])
    const dataStatusofProduct = ['Active', 'Inactive', 'Other']
    const dataReparation = ['Minor', 'Major', 'Overhaul', 'Other']
    const editable = ref(false)
    const installerDetailData = ref([])
    const loading = ref(false)

    const dataManufatures = ref([])
    const selectedManufatures = ref()

    const dataVendors = ref([])
    const selectedVendors = ref()
    const selectedVendors2 = ref()

    const selectedRequestType = ref()

    const dataSelectionRequester = ref([])
    const selectedSelectionRequester = ref()
    const selectedDataRequester = ref({
      email: null,
      phoneNumber: null,
    })

    const dataAssetCategories = ref([])
    const selectedCategories = ref()

    const dataAnswersReq = ref([])

    const dataAssetSubCategories = ref([])
    const selectedSubCategories = ref()

    const dataAssets = ref([])
    const selectedAsset = ref()

    const dataPayload = ref({
      wifiName: null,
      availablePort: null,
      workingPort: null,
      deadPort: null,
      ipAdress: null,
      subnetMask: null,
      gateway: null,
      totalPort: null,
      systemName: null,
      hostName: null,
      domainRole: null,
      dns: null,
      os: null,
      softwareName: null,
      softwareVersion: null,
      accessType: null,
      airFlow: null,
      coolingDesign: null,
      coolingType: null,
      coolingCapacity: null,
      inputPowerKw: null,
      panelType: null,
      transferSwitch: null,
      phaseType: null,
      sensorType: null,
      supressType: null,
      fillDate: null,
      expireDate: null,
      extenguisherType: null,
      kva: null,
      kw: null,
      machineType: null,
      systemTrigger: null,
      model: null,
      formFactor: null,
      battery: null,
      height: null,
      width: null,
      depth: null,
      weight: null,
      backupKw: null,
      backupKva: null,
      backupDuration: null,
      inputKw: null,
      inputKva: null,
      outputKw: null,
      outputKva: null,
      batteryType: null,
      voltage: null,
      cells: null,
      controllerStatus: null,
      utpType: null,
      node: null,
      connectionType: null,
      patch: null,
      cableManagement: null,
      rackUnit: null,
      crossConnection: null,
      foType: null,
      softwareType: null,
      version: null,
      customerId: null,
      locationId: null,
      roomId: null,
      assetSubCategoryId: null,
      manufacturerId: null,
      vendorId: null,
      name: null,
      modelAsset: null,
      productNumber: null,
      serialNumber: null,
      serviceTag: null,
      assetTag: null,
      rackNumber: null,
      uNumber: null,
      uSize: null,
      status: null,
      diskDrives: [
        // {
        //   model: 'Disk Drive Model 1',
        //   serialNumber: 'SN1234567890A',
        //   capacity: '128 GB',
        // },
      ],
      memories: [
        // {
        //   model: 'Memory Model 1',
        //   serialNumber: 'SN1234567890A',
        //   capacity: '128 GB',
        // },
      ],
      processors: [
        // {
        //   model: 'Processor Model 1',
        //   speed: '3.6 GHz',
        //   threads: 6,
        //   cores: 6,
        // },
      ],
      procurements: {
        vendorId: null,
        purchaseDate: null,
        No: null,
        Date: null,
        value: null,
        warranty: null,
        warrantyStart: null,
        warrantyEnd: null,
      },
      maintenances: {
        vendorId: null,
        purchaseDate: null,
        No: null,
        Date: null,
        value: null,
        warranty: null,
        warrantyStart: null,
        warrantyEnd: null,
      },

      date: null,
      requestId: null,
      approverId: null,
      testingReason: null,
      productStatus: null,
      otherProductStatus: null,
      reparation: null,
      explaination: null,
      additionId: null,
      installer: [
        // {
        //   id: 4,
        //   name: 'Ahmad',
        //   title: 'Tukang',
        //   vendorId: 1,
        // },
        // {
        //   name: 'Ahmad Abdul Azis',
        //   title: 'Tukang',
        //   vendorId: 1,
        // },
      ],
      answers: [
        // {
        //   id: 5,
        //   idQuestion: 1,
        //   answer: 'no',
        // },
      ],
    })
    const dataSelectedAsset = ref({
      manufacturerId: null,
      modelAsset: null,
      productNumber: null,
      serialNumber: null,
      serviceTag: null,
      assetTag: null,
    })
    const handleChangeSelectedRequester = e => {
      if (e) {
        selectedDataRequester.value = dataSelectionRequester.value.find(data => data.id == e)
        dataPayload.value.approverId = e
      }
    }
    const addInstallerDetail = () => {
      installerDetailData.value.push({ _id: uniqueId('add-'), name: '', title: '', vendorId: null })
    }
    const deleteInstallerDetail = id => {
      installerDetailData.value = installerDetailData.value.filter(trb => trb._id !== id)
    }
    onBeforeMount(() => {
      getInitialData()
    })
    // created() {

    // }
    const getInitialData = async () => {
      loading.value = true
      const resUser = await getAllUser()
      dataSelectionRequester.value = resUser.users
      const resChangeRequest = await getChangeRequestById(route.params.id)
      // console.log(resChangeRequest, '<<<<<HALLO')

      const categories = await getCategories()
      const assetCategory = await getAssetCategory()
      const filteredAssetCategory = assetCategory.categories.filter(
        cat => cat.name != 'Software' && cat.name != 'Infrastructure' && cat.name != 'Monitoring',
      )
      const {
        AssetSubCategory,
        Customer,
        HardwareInformation,
        Location,
        Room,
        TechnicalActiveDevice,
        TechnicalFacility,
        TechnicalPassiveDevice,
        TechnicalSoftware,
        ProcurementInformation,
        MaintenanceInformation,
        Components,
        ...rest
      } = resChangeRequest.rqDetail.Asset

      // console.log(filteredAssetCategory, '<<<ASET CATEGORY')
      let catAssets

      const manufactures = await getManufactures()
      dataManufatures.value = manufactures.manufacturers

      const vendors = await getVendors()
      dataVendors.value = vendors.vendor
      // const requestChange = await getChangeRequestById(route.params.id)
      console.log(resChangeRequest, '<<<<<CHANGE')
      selectedRequestType.value = resChangeRequest.rqDetail.type
      await handleChangeRequestType(resChangeRequest.rqDetail.type)
      selectedCategories.value = resChangeRequest.rqDetail.Asset
        ? resChangeRequest.rqDetail.Asset.AssetSubCategory.assetCategoryId
        : null
      await handleChangeCategories(
        resChangeRequest.rqDetail.Asset
          ? resChangeRequest.rqDetail.Asset.AssetSubCategory.assetCategoryId
          : null,
      )
      selectedSubCategories.value = resChangeRequest.rqDetail.Asset
        ? resChangeRequest.rqDetail.Asset.AssetSubCategory.id
        : null
      await handleChangeSubCategories(
        resChangeRequest.rqDetail.Asset
          ? resChangeRequest.rqDetail.Asset.AssetSubCategory.id
          : null,
      )
      selectedAsset.value = resChangeRequest.rqDetail.Asset.id
      await handelChangeAssets(resChangeRequest.rqDetail.Asset.id)

      dataPayload.value.tbMasterId = resChangeRequest.rqDetail.DismantlingMasters.length
        ? resChangeRequest.rqDetail.DismantlingMasters[0].id
        : null
      dataPayload.value.date = resChangeRequest.rqDetail.DismantlingMasters.length
        ? moment(resChangeRequest.rqDetail.DismantlingMasters[0].date)
        : null

      dataPayload.value.testingReason = resChangeRequest.rqDetail.DismantlingMasters.length
        ? resChangeRequest.rqDetail.DismantlingMasters[0].DismantlingRemark.testingReason
        : null
      dataPayload.value.productStatus = resChangeRequest.rqDetail.DismantlingMasters.length
        ? resChangeRequest.rqDetail.DismantlingMasters[0].DismantlingRemark.productStatus
        : null
      dataPayload.value.otherProductStatus = resChangeRequest.rqDetail.DismantlingMasters.length
        ? resChangeRequest.rqDetail.DismantlingMasters[0].DismantlingRemark.otherProductStatus
        : null
      // dataPayload.value.reparation = resChangeRequest.rqDetail.DismantlingMasters.length
      //   ? resChangeRequest.rqDetail.DismantlingMasters[0].DismantlingRemark.reparation
      //   : null
      installerDetailData.value = resChangeRequest.rqDetail.DismantlingMasters.length
        ? resChangeRequest.rqDetail.DismantlingMasters[0].DismantlingInstallers
        : []
      dataPayload.value.approverId = resChangeRequest.rqDetail.DismantlingMasters.length
        ? resChangeRequest.rqDetail.DismantlingMasters[0].approverId
        : null
      selectedSelectionRequester.value = resChangeRequest.rqDetail.DismantlingMasters.length
        ? resChangeRequest.rqDetail.DismantlingMasters[0].approverId
        : null

      await handleChangeSelectedRequester(
        resChangeRequest.rqDetail.DismantlingMasters.length
          ? resChangeRequest.rqDetail.DismantlingMasters[0].approverId
          : null,
      )

      dataPayload.value.answers = resChangeRequest.rqDetail.DismantlingMasters.length
        ? resChangeRequest.rqDetail.DismantlingMasters[0].DismantlingRemark.DismantlingAnswers
            .length
          ? resChangeRequest.rqDetail.DismantlingMasters[0].DismantlingRemark.DismantlingAnswers
          : []
        : []

      dataPayload.value.answers.forEach((e, idx) =>
        console.log('inisatu2', dataPayload.value.answers[idx].answer),
      )
      // console.log('cekdisini', dataPayload.value.answers[0].answer)
      dataAnswersReq.value = resChangeRequest.rqDetail.DismantlingMasters.length
        ? resChangeRequest.rqDetail.DismantlingMasters[0].DismantlingRemark.DismantlingAnswers
            .length
          ? resChangeRequest.rqDetail.DismantlingMasters[0].DismantlingRemark.DismantlingAnswers
          : []
        : []
      console.log('inidataanswerreq', dataAnswersReq.value)
      dataPayload.value.procurements = { ...resChangeRequest.rqDetail.Asset.ProcurementInformation }
      dataPayload.value.maintenances = { ...resChangeRequest.rqDetail.Asset.MaintenanceInformation }

      const additionRemarkData = await getListAdditionQuestion()
      dataAdditionRemark.value = additionRemarkData.question
      console.log('inidatadd', dataAdditionRemark.value)
      // console.log(additionRemarkData, '<<<<<HALLO')
      dataPayload.value = {
        ...dataPayload.value,
        assetSubCategoryId: String(AssetSubCategory.id),
        name: rest.name,
        modelAsset: rest.model,
        productNumber: rest.productNumber,
        serialNumber: rest.serialNumber,
        serviceTag: rest.serviceTag,
        assetTag: rest.assetTag,
        rackNumber: rest.rackNumber,
        manufacturerId: rest.manufacturerId,
        uNumber: rest.uNumber,
        uSize: rest.uSize,
        dmMasterId: resChangeRequest.rqDetail.DismantlingMasters.length
          ? resChangeRequest.rqDetail.DismantlingMasters[0].id
          : null,
        procurements: {
          ...ProcurementInformation,
        },
        maintenances: {
          ...MaintenanceInformation,
        },
        ...TechnicalActiveDevice,
        ...TechnicalFacility,
        ...TechnicalPassiveDevice,
        ...TechnicalSoftware,
        ...Components,
        cableManagement: TechnicalPassiveDevice?.cableManagement ? 'yes' : 'no',
        crossConnection: TechnicalPassiveDevice?.crossConnection ? 'yes' : 'no',
      }
      console.log('disinipayload', dataPayload)
      loading.value = false
    }
    const handleChangeRequestType = async e => {
      const assetCategory = await getAssetCategory()
      const filteredAssetCategory = assetCategory.categories.filter(
        cat => cat.name != 'Software' && cat.name != 'Infrastructure' && cat.name != 'Monitoring',
      )
      const lastAssetCategory = filteredAssetCategory.filter(cat => {
        if (e == 'Troubleshooting') {
          if (cat.name != 'Passive Devices') return cat
        } else return cat
      })
      dataAssetCategories.value = lastAssetCategory
      dataPayload.value.type = e
      // dataAssetSubCategories.value = lastAssetCategory
    }
    const handleChangeCategories = async e => {
      if (e) {
        const subCategories = dataAssetCategories.value.find(cat => cat.id == e)
        console.log(
          dataAssetCategories.value.find(cat => cat.id == e),
          '<<<<HALO',
        )
        dataAssetSubCategories.value = subCategories.AssetSubCategories
      }
    }
    const handleChangeSubCategories = async e => {
      if (e) {
        const assets = await getAssets({ type: e })
        dataAssets.value = assets.assets
        dataPayload.value.assetSubCategoryId = e
      }
    }

    const handelChangeAssets = async e => {
      const assets = dataAssets.value.find(asset => asset.id == e)
      dataPayload.value.assetId = assets.id
      dataSelectedAsset.value = assets
      console.log(assets, '<<<ASSETs')
    }

    const changeEdittable = () => {
      editable.value = !editable.value
    }
    const handleChangeProcurementVendor = async e => {
      const vendorProcurement = dataVendors.value.find(data => data.id == e)
      dataPayload.value.procurements.email = vendorProcurement.email
      dataPayload.value.procurements.mobileNumber = vendorProcurement.mobileNumber
    }
    const handleChangeMaintenancesVendor = async e => {
      const vendorMaintenance = dataVendors.value.find(data => data.id == e)
      dataPayload.value.maintenances.email = vendorMaintenance.email
      dataPayload.value.maintenances.mobileNumber = vendorMaintenance.mobileNumber
    }
    const handleSubmit = async () => {
      dataPayload.value.installer = installerDetailData.value
      dataPayload.value.requestId = route.params.id

      const payload = dataPayload.value
      const data = await editOrCreateDismantle(payload)
      if (data) {
        notification.success({
          message: 'Berhasil Submit',
          description: 'Data Dismantle Berhasil di Submit',
        })
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Internal Server Error',
        })
      }
      // setTimeout(() => {
      router.push({ name: 'Dismantle' })
      // }, 1000)
    }
    const handleChangeAnswerRemark = (e, item, index) => {
      console.log(item.id)
      dataPayload.value.answers[index] = {
        // "id": 5,
        ...dataPayload.value.answers[index],
        idQuestion: item.id,
        answer: e.target.value,
      }
    }
    // const dataSource = [
    //   {
    //     device: assets.value.asset_name,
    //     brand: manufactures.value.manufacturer_name,
    //     productNo: assets.value.product_no,
    //     serialNo: assets.value.serial_no,
    //   },
    // ]
    return {
      loading,
      columns,
      // detail troubleshoot
      facility,
      catFacility,
      installerDetailData,
      // Last Section
      dataTestingReason,
      dataStatusofProduct,
      dataReparation,
      selectedReparation,
      selectedTestingReason,
      selectedStatusofProduct,
      editable,
      dataAdditionRemark,

      changeEdittable,

      addInstallerDetail,
      deleteInstallerDetail,
      handleSubmit,
      // dataSource,

      dataPayload,
      dataSelectedAsset,
      dataVendors,
      selectedVendors,
      selectedVendors2,
      selectedRequestType,
      dataSelectionRequester,
      selectedSelectionRequester,
      selectedDataRequester,
      dataAssetCategories,
      selectedCategories,
      dataAssetSubCategories,
      selectedSubCategories,
      dataAssets,
      selectedAsset,
      dataManufatures,
      selectedManufatures,

      handleChangeCategories,
      handleChangeSubCategories,
      handelChangeAssets,
      handleChangeAnswerRemark,
      handleChangeProcurementVendor,
      handleChangeMaintenancesVendor,
      handleChangeSelectedRequester,
      dataAnswersReq,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-.ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
